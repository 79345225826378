<template>
  <div class="DeveloperMenu">
    <LargeButton :label="$locale['wiki']" :class="`${mkLink('/?feed=wiki', 'on')}`" @click="goToNav({ view: _view }, '/?feed=wiki')" />
  </div>
</template>

<script>
export default {
  props: ["_view", "modal"],
};
</script>
